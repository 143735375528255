export class SpecialTaxConditionDs {
    id: number;
    name: string;
    amount: number;
    typeId: number;
    typeName: string;
    applyTypeId: number;
    applyTypeName: string;

    constructor(){
    }
}