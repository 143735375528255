export class UserDs {
    id: number;
    firstName: string;
    lastName: string;
    isActive : boolean;
    phone : string;
    email : string;
    statusName : string;
    code: string;

}