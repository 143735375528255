import { ProvinceDs } from "./province.ds";

export class AddressDs {
    id: number;
    address: string;
    postalCode: string;
    lat: number;
    long: number;
    city: string;
    province: ProvinceDs;

    constructor(){
        this.province = new ProvinceDs();
    }
}