import { Time } from "@angular/common";
import { ZoneDS } from "./Zone.ds";

export class ZonesDeliveryPriceDS {
    id : number;
    startTime : string;
    endTime : string;
    isSos : boolean;
    maxTotalInvoiceItemsPriceExclusive : number;
    minTotalInvoiceItemsPriceInclusive : number;
    fee : number;
    createdDate : Date;
    zone : ZoneDS;


    constructor(){
        this.zone = new ZoneDS();
    }
}
